import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 720.000000 564.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,564.000000) scale(0.100000,-0.100000)">
          
          <path d="M4768 3582 c-26 -5 -29 -30 -26 -197 l3 -150 43 -3 42 -3 0 174 c0
173 0 175 -22 179 -13 2 -31 2 -40 0z"/>
<path d="M4924 3573 c-12 -12 -14 -46 -12 -176 l3 -162 113 -3 112 -3 0 30 c0
36 -21 45 -91 39 l-44 -3 0 40 0 40 58 3 57 3 0 35 0 36 -57 -4 -58 -3 0 35 0
35 65 5 c62 5 65 6 66 32 1 33 -20 40 -123 37 -49 -1 -79 -7 -89 -16z"/>
<path d="M4495 3570 c-3 -5 -11 -8 -18 -6 -7 1 -9 1 -4 -1 13 -8 7 -28 -13
-41 -12 -7 -18 -17 -15 -22 4 -6 12 -7 18 -3 17 10 107 6 121 -6 9 -8 20 -3
41 19 28 29 28 30 9 45 -12 9 -31 13 -49 10 -22 -4 -26 -2 -15 5 11 7 4 10
-27 10 -23 0 -44 -4 -48 -10z m15 -50 c0 -5 -7 -10 -15 -10 -8 0 -15 5 -15 10
0 6 7 10 15 10 8 0 15 -4 15 -10z m90 -10 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4
10 3 6 8 10 11 10 2 0 4 -4 4 -10z"/>
<path d="M4420 3535 c-11 -13 -10 -14 4 -9 9 3 16 10 16 15 0 13 -6 11 -20 -6z"/>
<path d="M4410 3500 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M4411 3464 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M4586 3314 c-3 -8 -10 -12 -16 -9 -5 3 -10 2 -10 -4 0 -5 5 -13 11
-16 6 -4 14 -17 19 -29 6 -16 8 -9 7 27 -2 52 -2 54 -11 31z"/>
<path d="M4610 3300 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M4620 3268 c0 -4 4 -8 9 -8 6 0 12 4 15 8 3 5 -1 9 -9 9 -8 0 -15 -4
-15 -9z"/>
<path d="M0 90 l0 -90 3600 0 3600 0 0 90 0 90 -3600 0 -3600 0 0 -90z"/>

        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
